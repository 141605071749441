<template>
  <section class="ProjectNew">
    <div class="Title">
      <h3 class="subject">新着のプロジェクト</h3>
      <title-link
        v-if="hasLink"
        :link-href="titleLinkHref"
        :link-target="titleLinkTarget"
        :click-tracking-category="eventCategory('pc', 'project')"
        :click-tracking-label="eventLabel('new', 'more')"
      >
        {{ titleLinkText }}
      </title-link>
    </div>
    <scroll-container>
      <ul class="projects">
        <project-item
          v-for="project in projects"
          :key="project.id"
          class="project-item"
          :project="project"
          :project-with-detail="false"
          column-class="ProjectItem--4column ProjectItem--4column_with-button"
          :click-tracking-category="eventCategory('pc', 'project')"
          :click-tracking-label="eventLabel('new', 'item', i)"
        />
      </ul>
    </scroll-container>
  </section>
</template>

<script>
import _ from 'lodash';
import api from '@/modules/api/v2/projects';
import clickTrackable from '@/mixins/clickTrackable';
import projectsLoadable from '@/mixins/projectsLoadable';
import ProjectItem from '@/pc/components/ProjectItem';
import titleDefinition from '@/mixins/titleDefinition';
import TitleLink from '@/pc/components/top/TitleLink';
import ScrollContainer from '@/pc/components/top/ScrollContainer';

export default {
  name: 'ProjectNew',
  components: {
    ProjectItem,
    ScrollContainer,
    TitleLink,
  },
  mixins: [clickTrackable, projectsLoadable, titleDefinition],
  props: {
    titleLinkHref: {
      type: String,
      default() {
        return '/discover/new';
      },
    },
  },
  methods: {
    async fetchProjects() {
      return api.fetchNew({
        per_page: 22,
      });
    },
    manipulateProjects(projects) {
      // fetchした最新プロジェクトからランダムに16プロジェクト返却する
      return _.sampleSize(projects, 16);
    },
  },
};
</script>

<style scoped>
.ProjectNew {
  margin: 0 auto 36px;
  position: relative;
  width: 1000px;
}

.projects {
  display: flex;
  flex-wrap: nowrap;
}

.Title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
}

.subject {
  font-size: 20px;
}
</style>
